<template>
  <div class="profile">
    <header>
      <div class="flexbox">
        <van-icon
          v-if="unReadCount > 0"
          name="chat-o"
          class="ml-auto"
          dot
          @click="$router.push({ name: 'MessList' })"
        />
        <van-icon
          v-else
          name="chat-o"
          class="ml-auto"
          @click="$router.push({ name: 'MessList' })"
        />
        <van-icon name="setting-o" @click="$router.push({ name: 'Profile' })" />
      </div>
      <div class="info flexbox" @click="$router.push({ name: 'Profile' })">
        <div
          class="avatar"
          :style="{ 'background-image': `url(${avatar})` }"
        ></div>
        <div class="flexbox vertical justify-center">
          <div class="f-18">{{ userInfo.name }}</div>
          <div class="f-14">会员号：{{ userInfo.sCustNo }}</div>
        </div>
      </div>
      <div class="flexbox f-12 space-around data">
        <div>
          <div class="number">{{ wallet.balance }}</div>
          <div>{{ $t('Profile.nhoflm') }}</div>
        </div>
        <div>
          <div class="number">{{ wallet.payable }}</div>
          <div>{{ $t('Profile.kzmuj7') }}</div>
        </div>
        <div>
          <div class="number">{{ wallet.commission }}</div>
          <div>{{ $t('Profile.63ywu8') }}</div>
        </div>
        <div>
          <div class="number">{{ points }}</div>
          <div>{{ $t('Profile.quago8') }}</div>
        </div>
      </div>
    </header>
    <main>
      <div class="card hidden round actions">
        <div class="grid">
          <router-link tag="div" class="cell" :to="{ name: 'Charge' }">
            <div class="cell-icon charge"></div>
            <div>{{ $t('Profile.224w4a') }}</div>
          </router-link>
          <!-- <div class="cell" @click="$router.push({ name: 'Withdraw' })">
            <div class="cell-icon withdraw"></div>
            <div>{{ $t('Profile.z1hgp3') }}</div>
          </div> 
          <div class="cell" @click="$router.push({ name: 'BankCard' })">
            <div class="cell-icon bank-card"></div>
            <div>{{ $t('Profile.h0r7hm') }}</div>
          </div>
          <div
            class="cell"
            @click="$router.push({ name: 'TransactionPassword' })"
          >
            <div class="cell-icon password"></div>
            <div>{{ $t('Profile.0marzg') }}</div>
          </div>
          -->
          <router-link tag="div" class="cell" :to="{ name: 'Transactions' }">
            <div class="cell-icon records"></div>
            <div>{{ $t('Profile.g55oyi') }}</div>
          </router-link>

          <div class="cell" @click="$router.push({ name: 'ApplicationList' })">
            <div class="cell-icon application"></div>
            <div>{{ $t('Profile.fxho2e') }}</div>
          </div>
          <div class="cell" @click="$router.push({ name: 'OrderBill' })">
            <div class="cell-icon bank-card"></div>
            <div>{{ $t('Profile.账单') }}</div>
          </div>
        </div>
      </div>
      <div class="card round list">
        <ul>
          <li
            class="flexbox list-item"
            @click="$router.push({ name: 'RecStorageAddr' })"
          >
            <span class="item-icon site"></span>
            <span>{{ $t('Profile.中国仓库收货地址') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox list-item"
            @click="$router.push({ name: 'AddressList' })"
          >
            <span class="item-icon">
              <font-awesome-icon :icon="['far', 'address-book']" size="large" />
            </span>
            <span>{{ $t('Profile.71kvmc') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox hidden list-item"
            @click="$router.push({ name: 'MyPoints' })"
          >
            <span class="item-icon points"></span>
            <span>{{ $t('Profile.3ubk5g') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox hidden list-item"
            @click="$router.push({ name: 'CustomerService' })"
          >
            <span class="item-icon faq"></span>
            <span>{{ $t('Profile.x409h5') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox list-item"
            @click="$router.push({ name: 'ChangePassword' })"
          >
            <span class="item-icon login"></span>
            <span>{{ $t('Profile.u040hi') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox list-item"
            @click="$router.push({ name: 'BindPhone' })"
          >
            <span class="item-icon phone"></span>
            <span>{{ $t('Profile.34fpid') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox hidden list-item"
            @click="$router.push({ name: 'InviteUser' })"
          >
            <span class="item-icon phone"></span>
            <span>{{ $t('Profile.邀请好友') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
          <li
            class="flexbox list-item"
            @click="$router.push({ name: 'About' })"
          >
            <!-- <font-awesome-icon :icon="['far', 'user']" /> -->

            <font-awesome-icon
              class="item-icon"
              :icon="['far', 'calendar-alt']"
            />
            <span>{{ $t('Profile.关于我们') }}</span>
            <van-icon name="arrow" class="ml-auto" />
          </li>
        </ul>
      </div>
      <div :class="currentWexin == true ? 'hidden' : 'footer-btn'">
        <van-button type="danger" @click="onLogout">{{
          $t('Profile.uz6phm')
        }}</van-button>
      </div>
    </main>
  </div>
</template>
<script>
import { logout, GetWalletInfoByCustID } from '@/api/account.js';
import { getUnReadMsgCount } from '@/api/website.js';
import { BASE_URL, isWeiXin } from '@/api/index.js';
export default {
  data() {
    return {
      nickname: '大椰子',
      points: 0,
      unReadCount: 0,
      avatar: '',
      wallet: {
        balance: 0,
        payable: 0,
        commission: 0,
      },
      currentWexin: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  mounted() {
    this.loadData();
    this.currentWexin = isWeiXin();
  },
  methods: {
    loadData() {
      var picurl = this.$store.getters.userInfo.avatar;
      if (picurl != null && picurl.startsWith('/')) {
        this.avatar = BASE_URL + picurl;
      } else {
        this.avatar = picurl;
      }
      getUnReadMsgCount().then(res => {
        if (res.Result == true) {
          this.unReadCount = res.Code;
        }
      });

      GetWalletInfoByCustID().then(res => {
        console.log(res);
        if (res.Result == true) {
          this.wallet.payable = res.DataDict['data'].fToPay;
          console.log(res.DataDict['data'].fToPay);
          this.wallet.balance = res.DataDict['data'].fRest;
          this.wallet.commission = res.DataDict['data'].fYongjin;
        }
      });
    },

    onLogout() {
      logout().then(() => {
        this.$router.replace({ name: 'Login' });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
header {
  background: linear-gradient(#5b57b5 0%, #697fc8 100%);
  height: rem(400px);
  padding: rem(40px);
  color: #fff;
  .van-icon {
    font-size: 20px;
    & + .van-icon {
      margin-left: rem(34px);
    }
  }
}
main {
  position: relative;
  top: rem(-80px);
  margin: rem(20px);
}
.info {
  margin-top: rem(20px);
  margin-bottom: rem(40px);
}
.avatar {
  width: rem(160px);
  height: rem(160px);
  margin-right: rem(40px);
  border-radius: 50%;
  border: 2px solid #ffffff;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.number {
  margin-bottom: rem(21px);
}
.data {
  & > div {
    text-align: center;
  }
}
.hidden {
  display: none;
}
.card {
  background-color: #ffffff;
  padding-top: rem(40px);
  padding-bottom: 0;
  box-shadow: 3px 3px 5px #858585;
  &.list {
    padding: rem(24px);
  }
  & + .card {
    margin-top: rem(20px);
  }
  &.actions {
    .van-icon,
    .icon {
      font-size: 30px;
      margin-bottom: rem(26px);
    }
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 25%);
}
.cell {
  text-align: center;
  margin-bottom: rem(48px);
}
.list-item {
  align-items: center;
  padding: rem(24px) 0;
  & + .list-item {
    border-top: 1px solid rgba(#9c9bb4, 0.3);
  }
  .van-icon {
    font-size: 16px;
    &:first-child {
      margin-right: rem(10px);
    }
  }
  .van-icon-arrow {
    color: #9c9bb4;
  }
}
.cell-icon {
  width: rem(52px);
  height: rem(52px);
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  &.charge {
    background-image: url(~@/assets/images/charge.png);
  }
  &.withdraw {
    background-image: url(~@/assets/images/withdraw.png);
  }
  &.application {
    background-image: url(~@/assets/images/application.png);
  }
  &.bank-card {
    background-image: url(~@/assets/images/card.png);
  }
  &.records {
    background-image: url(~@/assets/images/records.png);
  }
  &.password {
    background-image: url(~@/assets/images/password.png);
  }
}
.item-icon {
  width: rem(32px);
  height: rem(32px);
  margin-right: rem(24px);
  background-size: contain;
  background-repeat: no-repeat;
  color: #b9b8d4;
  &.site {
    background-image: url(~@/assets/images/site.png);
  }
  &.login {
    background-image: url(~@/assets/images/login.png);
  }
  &.points {
    background-image: url(~@/assets/images/points.png);
  }
  &.faq {
    background-image: url(~@/assets/images/faq.png);
  }
  &.phone {
    background-image: url(~@/assets/images/phone.png);
  }
}
.footer-btn {
  margin-top: rem(48px);
  text-align: center;
  .van-button {
    width: 80%;
  }
}

.svg-inline--fa.fa-w-14 {
  width: 1.275em;
}
</style>
